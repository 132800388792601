export enum ListenerEventsType {
    APP_INIT = 'APP_INIT',
    SESSION_INFO_OBTAIN = 'SESSION_INFO_OBTAIN',
    SESSION_INFO_OBTAIN_WITH_MISSING_FIELDS = 'SESSION_INFO_OBTAIN_WITH_MISSING_FIELDS',
    SESSION_INFO_OBTAIN_WITH_MISSING_VALUES = 'SESSION_INFO_OBTAIN_WITH_MISSING_VALUES',
    SESSION_INFO_INVALID_JSON = 'SESSION_INFO_INVALID_JSON',
    LANDING_SCREEN = 'LANDING_SCREEN',
    SESSION_INFO_MISSING = 'SESSION_INFO_MISSING',
    SPEED_PAY_SCRIPT_INITIALIZED = 'SPEED_PAY_SCRIPT_INITIALIZED',
    SPEED_PAY_SCRIPT_INITIALIZED_FAILED = 'SPEED_PAY_SCRIPT_INITIALIZED_FAILED',
    CREATE_FUNDING_ACCOUNT = 'CREATE_FUNDING_ACCOUNT',
    CREATE_FUNDING_ACCOUNT_FAILED = 'CREATE_FUNDING_ACCOUNT_FAILED',
    PAYMENT_SUBMITTED = 'PAYMENT_SUBMITTED',
    PAYMENT_SUBMITTED_FAILED = 'PAYMENT_SUBMITTED_FAILED',
    GENERIC_ERROR_ENCOUNTERED = 'GENERIC_ERROR_ENCOUNTERED',
    INFO_ICON_TAPPED = 'INFO_ICON_TAPPED',
    ACCOUNT_DETAIL_SHOW = 'ACCOUNT_DETAIL_SHOW',
    ACCOUNT_DETAIL_HIDE = 'ACCOUNT_DETAIL_HIDE',
}

export interface ListenerEvent {
    eventType: ListenerEventsType;
    eventValue?: string;
}