import {BrowserModule} from "@angular/platform-browser";
import {Component, inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ExternalScriptModel} from "./speed-pay/models/interfaces/external-script.model";
import {AppConfigService} from "./config/app-config.service";
import {ScriptLoaderService} from "./speed-pay/services/script-loader.service";
import {CommonModule} from "@angular/common";
import {catchError, EMPTY, filter, from, Observable, of, Subscription, switchMap, tap, throwError} from "rxjs";
import {AciModel} from "./speed-pay/models/interfaces/aci.model";
import {SpeedPayService} from "./speed-pay/services/speed-pay.service";
import {LandingComponent} from "./speed-pay/components/landing/landing.component";
import {CustomerDetails} from "./speed-pay/models/interfaces/customer-details";
import {SessionStorageService} from "./core/services/session-storage.service";
import {PaymentConfirmationComponent} from "./speed-pay/components/payment-confirmation/payment-confirmation.component";
import {PaymentConfirmationResponse} from "./speed-pay/models/interfaces/payment-confirmation-response";
import {PaymentErrorComponent} from "./speed-pay/components/payment-error/payment-error.component";
import {EventBusService} from "./core/services/event-bus.service";
import {ListenerEventsType} from "./speed-pay/models/enums/listener-events-type";
import {LoaderService} from "./speed-pay/services/loader.service";
import {ISdkInitResponse} from "./speed-pay/models/interfaces/sdk-init-response";
import {AciPaymentDetails} from "./speed-pay/models/interfaces/aci-payment-details";
import {AciExpiredAccessTokenErrorResponse} from "./speed-pay/models/interfaces/aci-error-responses";
import {FundingAccountsResponse} from "./speed-pay/models/interfaces/funding-account-response";
import {SendPaymentResponse} from "./speed-pay/models/interfaces/send-payment-response";

declare let aci: AciModel;

@Component({
    selector: 'cma-root',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        LandingComponent,
        PaymentConfirmationComponent,
        PaymentErrorComponent
    ],
    providers: [
        BrowserModule,
        ReactiveFormsModule,
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public customerDetails: CustomerDetails = null;
    public paymentResponse: PaymentConfirmationResponse = null;
    public isPaymentSubmitted = false;
    protected isLoading$: Observable<boolean>;
    protected isError = false;

    // dependencies
    private _renderer = inject(Renderer2);
    private _config = inject(AppConfigService);
    private _loaderService = inject(LoaderService);
    private _sessionStorageService = inject(SessionStorageService);
    private _speedPayService = inject(SpeedPayService);
    private _scriptLoaderService = inject(ScriptLoaderService);
    private _eventBusService = inject(EventBusService);
    private speedPayScript!: ExternalScriptModel;
    private subscriptions: Subscription[] = [];

    public ngOnInit(): void {
        this.isLoading$ = this._loaderService.getLoader();
        this._loaderService.setLoader(true);

        // the next line for testing purposes only
        this._sessionStorageService.setSessionInfoObject();

        // subscribing to all mobile events
        this.subscriptions.push(
            this._eventBusService.eventBus
                .pipe(filter((event: ListenerEventsType) => !!event))
                .subscribe((event: ListenerEventsType) => {
                    this.postWebMessage(event);
                }),
        );

        this.subscriptions.push(
            of(this._sessionStorageService.getSessionInfoObject())
                .pipe(
                    tap((sessionObj: CustomerDetails) => {
                        // initialize customerDetails object
                        this.customerDetails = sessionObj;
                        return this.customerDetails;
                    }),
                    switchMap((sessionInfo: CustomerDetails) => {
                        // initialize speedPay script
                        this.speedPayScript = {
                            src: this._config
                                .getSpeedPayScriptEndpoint()
                                .replace('{billerId}', sessionInfo.customerInfo.billerId),
                            loaded: false,
                            name: 'speed-pay-init',
                        };

                        // load script in the head of the index.html page
                        return this._scriptLoaderService
                            .loadScript(this.speedPayScript, this._renderer)
                            .pipe(
                                catchError((error: any) => {
                                    this._loaderService.setLoader(false);
                                    // this.isError = true;
                                    throw new Error(error);
                                }),
                                filter((script: ExternalScriptModel) => script.loaded),
                                tap(() => this._eventBusService.emit(ListenerEventsType.LANDING_SCREEN)),
                            );
                    }),
                    // use the access token, and accountId from the session storage object to initialize the loaded script
                    switchMap(() => from(this._speedPayService.initSpeedPayScript(aci))
                        .pipe(
                            catchError((error: any) => {
                                // this._loaderService.setLoader(false);
                                this._eventBusService.emit(ListenerEventsType.SPEED_PAY_SCRIPT_INITIALIZED_FAILED)
                                // this.isError = true;
                                throw new Error(error);
                            }),
                            tap(() => this._eventBusService.emit(ListenerEventsType.SPEED_PAY_SCRIPT_INITIALIZED)),
                        )
                    ),

                    switchMap((handler: ISdkInitResponse) => {
                        if (handler && handler.paymentSheet) {
                            const convenienceFee = this._sessionStorageService.getConvenienceFee();
                            const principalAmount = this._sessionStorageService.getPaymentAmount();
                            handler.paymentSheet.displayName = 'Duke Energy';
                            handler.paymentSheet.merchantIdentifier = 'speedpay-duke';
                            handler.paymentSheet.onShow = (): void => {
                                const paymentDetails: AciPaymentDetails = {
                                    total: {
                                        label: 'Grand Total',
                                        amount: {currency: 'USD', value: principalAmount + convenienceFee}
                                    },
                                    displayItems: [
                                        {
                                            label: 'Principal Amount',
                                            amount: {currency: 'USD', value: principalAmount}
                                        },
                                        {
                                            label: 'Fees',
                                            amount: {currency: 'USD', value: convenienceFee}
                                        },
                                    ]
                                };
                                handler.paymentSheet.setPaymentDetails(paymentDetails);
                            };

                            // stop the loader
                            this._loaderService.setLoader(false);

                            // call back function - called after getting the token
                            return from(handler.createToken())
                                .pipe(
                                    catchError((error: any) => {
                                        this._eventBusService.emit(ListenerEventsType.CREATE_FUNDING_ACCOUNT_FAILED);
                                        throw new Error(error);
                                    }),
                                    tap((data: FundingAccountsResponse) => {
                                        // close the payment pop up
                                        handler.paymentSheet.complete('Success');

                                        // remove the 'Pay' button
                                        handler.unregister();

                                        this._eventBusService.emit(ListenerEventsType.CREATE_FUNDING_ACCOUNT);

                                        // return ACI createToken response (data) for further manipulation
                                        return data;
                                    })
                                );
                        }
                        return EMPTY;
                    }),
                    switchMap((data: FundingAccountsResponse) => {
                        // start the loader
                        this._loaderService.setLoader(true);
                        return this._speedPayService.sendPayment({
                            accountNumber: this.customerDetails.customerInfo.accountNumber,
                            aciFundingToken: data.token.id,
                            billerId: this.customerDetails.customerInfo.billerId,
                            feeAmount: this.customerDetails.paymentInfo.convenienceFee.toString(),
                            paymentAmount: this.customerDetails.paymentInfo.paymentAmount.toString(),
                        })
                    }),
                    catchError((error: AciExpiredAccessTokenErrorResponse) => {
                        this._eventBusService.emit(ListenerEventsType.PAYMENT_SUBMITTED_FAILED);
                        // this._loaderService.setLoader(false);
                        // this.isError = true;
                        return throwError(() => error);
                    })
                )
                .subscribe({
                    next: (resp: SendPaymentResponse) => {
                        this.paymentResponse = {
                            // this should be dynamics
                            ...this.customerDetails,
                            paymentMethod: this.customerDetails.paymentInfo.paymentMethod,
                            confirmationNumber: resp.confirmationNumber,
                            cpiMessage: resp.cpiMessage,
                        };
                        this.isPaymentSubmitted = true;
                        this._loaderService.setLoader(false);
                        this._eventBusService.emit(ListenerEventsType.PAYMENT_SUBMITTED);
                    },
                    error: (_err: ListenerEventsType) => {
                        // this._eventBusService.emit(ListenerEventsType.GENERIC_ERROR_ENCOUNTERED);
                        this._loaderService.setLoader(false);
                        this.isError = true;
                    }
                })
        )

    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    private postWebMessage(eventMessage: string) {
        console.log('postWebMessage', eventMessage);
        const listener: { postMessage: (arg0: string | ArrayBuffer) => void } = this.getWebAppListener();
        if (!!listener) {
            listener.postMessage(eventMessage);
        }
    }

    private getWebAppListener(): { postMessage: (arg0: string | ArrayBuffer) => void } | null {
        return window.webkit?.messageHandlers.cmaBillPayWebListener ??
            window["cmaBillPayWebListener"] ??
            null;
    }
}
