export const mockedData = {
    paymentInfo: {
        paymentAmount: 262.21,
        totalAmountDue: 262.21,
        convenienceFee: 1.25,
        paymentMethod: 'GooglePay'
    },
    customerInfo: {
        customerName: {
            firstName: 'Joseph',
            lastName: 'Manzewitsch'
        },
        billerId: 'CRNC',
        accountNumber: '910133362659',
        address: {
            address1: '6206 BIRKDALE VALLEY DR',
            address2: 'APT 523',
            city: 'CHARLOTTE',
            houseNumber: 1143,
            state: 'NC',
            zipCode: '28277'
        }
    },
    cpiMessage: {
        messageBody: 'The balance on this account is $262.21 due 06/16/2023 which includes a prior balance of $262.21 due 06/16/2023.',
        messagePriority: 'LOW'
    },
    tokens: {
        cmaToken: 'D0M1HW7hBHdEEP18IGHmuX4An0i6dr0BV98OJtmZv8flbQYGyLlonAvJRwZ84qxg',
        aciToken: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiZHVrZWFwcCIsInN1YiI6ImR1a2VhcHAiLCJhenAiOiJkdWtlYXBwIiwidXNlcm5hbWUiOiJ0a24uZXhjLmR1a2VhcHAiLCJDbGllbnRJZCI6ImR1a2UiLCJTaXRlSWQiOiJkdWtlIiwiVXNlcklkIjoiZHVrZWFwcCIsIlVzZXJHcm91cCI6ImR1a2VhcHAiLCJjcmVkaXRBY2NvdW50IjoiNDA5RUVBODQ1MjkwNDVBMEE0NkJBREUyNzVERkU2MDI0NDU4M0EyN0VFNjc4MzlDRTE5NTA3M0Q4Rjk3MjExRiIsInBlcm1pc3Npb25zIjpbIkEiLCJEIiwiRSIsIlMiLCI1IiwiRiIsIkNWQSJdLCJleHAiOjE3Mjg0OTY2MTl9.6yzY9TXvBbl47yWDqzZPZ_K_MRMNEiiD087hLkR42gntf2vBSTpLwKyUBR-abu_HdbV0F6tIjHdLztc4MwnV5w'
    }
}
