<div *ngIf="customerDetails"
     class="relative">
    <!-- Total Amount Due-->
    <cma-complex-card [cardTitle]="'Total Amount Due'"
                      [extraContainerCssClasses]="'mb-24'"
                      [extraHeaderCssClasses]="'text-gray-dark justify-between'"
                      [btnIconName]="'./assets/icons/tooltip-base.svg'"
                      [extraBtnCssClasses]="'icon-24 mb-2 !mx-auto'"
                      [rightHeaderAmount]="customerDetails.paymentInfo.totalAmountDue"
                      (rightHeaderBtnCallBack)="openDetailsModal()"
    >
    </cma-complex-card>

    <!-- Payment Amount -->
    <cma-complex-card [cardTitle]="'Payment Amount'"
                      [extraContainerCssClasses]="'mb-12'"
                      [extraHeaderCssClasses]="'justify-between p-12'">
        <div class="complexCardContent">
            <div class="flex flex-col p-16">
                <div class="flex flex-row justify-between mb-6">
                    <span>Payment Amount</span>
                    <span>{{ customerDetails.paymentInfo.paymentAmount | currency }}</span>
                </div>
                <div class="flex flex-row justify-between mb-6">
                    <span>Convenience Fee</span>
                    <span>{{ customerDetails.paymentInfo.convenienceFee | currency }}</span>
                </div>
                <div class="flex flex-row justify-between mb-6 font-bold">
                    <span>Total Payment Amount</span>
                    <span>{{ (customerDetails.paymentInfo.paymentAmount + customerDetails.paymentInfo.convenienceFee) | currency }}</span>
                </div>
            </div>
        </div>
    </cma-complex-card>

    <!-- middle screen text-->
    <p class="px-20 text-gray-dark text-left text-xs">
        Speedpay is a third-party vendor that charges a small convenience fee to process payments.
        No part of this fee is made payable to Duke Energy.
    </p>

    <!-- Payment Date-->
    <cma-complex-card [cardTitle]="'Payment Date'"
                      [extraContainerCssClasses]="'my-24'"
                      [extraHeaderCssClasses]="'justify-between p-12'">
        <div class="complexCardContent">
            <div class="flex flex-col p-16">
                <div class="flex flex-row justify-between mb-4">
                    <span>Today: </span>
                    <span>{{ today | date }}</span>
                </div>
            </div>
        </div>
    </cma-complex-card>
</div>

<!-- Account Details Modal -->
<ng-container *ngIf="isAccountDetailsModalOpen">
    <cma-modal (modalClosed)="closeDetailsModal()"
               [allowClose]="true"
               [contentTemplate]="detailsModalTemplate"
               [isOpen]="isAccountDetailsModalOpen"
               [alignment]="'bottom'"
               [primaryBtnText]="''"
               [secondaryBtnText]="''"
               [title]="'Account Details'"
               [titleTwClasses]="''"
    >
    </cma-modal>

    <ng-template #detailsModalTemplate>
        <div class="text-left container-xs md:container-6xl p-12 border border-gray rounded-md">
            <p class="m-0">{{ customerDetails.customerInfo.customerName.firstName }} {{ customerDetails.customerInfo.customerName.lastName }}</p>
            <p class="m-0">Account #{{ customerDetails.customerInfo.accountNumber }}</p>
            <div class="m-0">
                <p class="p-0 m-0">
                    {{ customerDetails.customerInfo.address.houseNumber }}
                    {{ customerDetails.customerInfo.address.address1 }}
                    {{ customerDetails.customerInfo.address.address2 }}</p>
                <p class="p-0 m-0">{{ customerDetails.customerInfo.address.city }}
                    , {{ customerDetails.customerInfo.address.state }} {{ customerDetails.customerInfo.address.zipCode }}</p>
            </div>
            <p class="m-0">{{ customerDetails.paymentInfo.totalAmountDue | currency }}</p>
            <p class="m-0">Payment due on {{ today | date }}</p>
            <p *ngIf="customerDetails.cpiMessage.messageBody"
               class="pt-2 m-0"
               [ngClass]="customerDetails.cpiMessage.messagePriority === 'HIGH' ? 'text-red-dark' : 'text-grey-dark'">
                {{ customerDetails.cpiMessage.messageBody }}
            </p>
        </div>
    </ng-template>
</ng-container>

